/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-19",
    versionChannel: "nightly",
    buildDate: "2024-02-19T00:06:25.813Z",
    commitHash: "8d9b50d4f459da54568de4ddcc81d61d3cc8dd07",
};
